@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
  max-width: 1200px;
}

@layer utilities {
  .line-clamp-3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3; /* Number of lines to show */
  }
}

@import url("https://fonts.googleapis.com/css2?family=Bellota+Text:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Cormorant:ital,wght@0,300..700;1,300..700&family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap");
