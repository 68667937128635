.section-header {
  position: relative; /* Establishes a positioning context for pseudo-element */
  display: inline-block; /* Makes the heading inline-block to fit content width */
}

.section-header::after {
  content: ""; /* Required to display the pseudo-element */
  display: block; /* Makes it a block to allow width and margin adjustments */
  width: 80%; /* Sets the width to 80% of the parent element */
  height: 4px; /* Height of the border */
  background: #4f46e5; /* Border color, match this with your text color */
  margin-top: 8px; /* Adjust the space between the text and the border */
  left: 10%; /* Positions the border starting from 10% of the parent element, centering it */
  position: absolute; /* Absolutely position the pseudo-element within the h1 */
}

.tags-input-container {
  /* border: 2px solid #000;
  padding: .5em;
  border-radius: 3px;
  width: min(80vw, 600px);
  margin-top: 1em; */
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
}

.tag-item {
  background-color: rgb(218, 216, 216);
  display: inline-block;
  padding: 0.5em 0.75em;
  border-radius: 4px;
}
.tag-item .close {
  height: 20px;
  width: 20px;
  background-color: rgb(48, 48, 48);
  color: #fff;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
  font-size: 15px;
  cursor: pointer;
}

.tags-input {
  border: none !important;
  outline: none !important;
  min-width: 8rem !important;
}

pre{
  font-family: inherit !important;
}